import React from 'react';

import '../styles/typography.scss';

function PartnersSlider({ images }: any) {

    return (
        <div className='container partners-slider'>
            {images && images.map((img: any, index: any) =>
                <img height={60} alt={img} key={'id_' + index} className="ms-4 me-4 mt-4" src={img} />
            )
            }
        </div>
    );
}

export default PartnersSlider;